import * as React from 'react';
import classNames from 'classnames';
import { useLocalStorage } from 'react-use';
import {
  ArrowSquareOut,
  CaretDown,
  CheckCircle,
  DotsThreeOutlineVertical,
  FolderPlus,
} from '@phosphor-icons/react';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';

import { Button, DropdownOption } from '../../../ui';
import { toast } from '../../../ui/components/Toast';
import Table, { ITableRow } from '../../../ui/components/Table/Table';
import AddToCaseModal from '../../../components/common/AddToCaseModal';
import AddressListFilter, {
  IFilters,
  defaultAddressListFilters,
} from '../../../components/Address/AddressList/AddressListFilter';
import AddressListAppliedFilter from '../../../components/Address/AddressList/AddressListAppliedFilter';
import Tab from '../../../../src/components/ui/components/Tab/Tab';
import SimpleDropdown from '../../../components/ui/components/Dropdown/SimpleDropdown';

import { useStagesListLiteGet } from '../../../api/stages';
import { useWorkspaceAssignmentCountGet } from '../../../api/workflows';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { addressApi, useAddressGetAddressList, useAddressGetAddressNameVerboses } from '../../../api/address';

import { IStageListLite } from '../../../api/dtos/stages';
import { IGetWorkspaceAssignmentCountsResponse } from '../../../api/dtos/workflows';
import { IAddressResponse } from '../../../api/dtos/address';
import { useWorkspace } from '../../../utils/helpers/common';
import { useGetAddressData } from '../addresses/AddressData';
import { isEqual } from 'lodash';
import { useCustomTagsGetCustomTags } from '../../../api/settings/customTags';
import TokenAccountModal, { TokenModalType } from '../../../components/Address/AddressList/TokenAccountModal';
import ResolveMultipleAlertsModal from '../../../components/common/ResolveMultipleAlertsModal';

interface Props {}

const tabData = ['assigned_to_me', 'unassigned', 'closed', 'all_addresses'];

const AddressList: React.FC<Props> = () => {
  const { state } = useAuth();
  const { getQueryParams, navigate } = useRouter();
  const {
    tag_type_verboses,
    tag_name_verboses,
    risk_level,
    currency,
    created_start_date,
    created_end_date,
    start_date,
    end_date,
    is_included_in_case,
    rule_name,
    workflow_stage,
    custom_tags,
    tab,
  } = getQueryParams();
  const workspace = useWorkspace();
  const [filterStorage, setFilterStorage] = useLocalStorage(
    `compass.storage.addressFilters.${workspace.slug}`,
    null
  );
  const [filters, setFilters] = React.useState<IFilters>({
    tag_type_verboses: tag_type_verboses ? tag_type_verboses.split(',') : [],
    tag_name_verboses: tag_name_verboses ? tag_name_verboses.split(',') : [],
    risk_level: risk_level ? risk_level.split(',').map(Number) : [],
    currency: currency ? currency.split(',').map(Number) : [],
    created_start_date: created_start_date && created_start_date !== 'null' ? created_start_date : null,
    created_end_date: created_end_date && created_end_date !== 'null' ? created_end_date : null,
    start_date: start_date && start_date !== 'null' ? start_date : null,
    end_date: end_date && end_date !== 'null' ? end_date : null,
    is_included_in_case:
      is_included_in_case === null || is_included_in_case === undefined ? null : Boolean(is_included_in_case),
    rule_name: rule_name ? rule_name.split(',') : [],
    workflow_stage: workflow_stage
      ? workflow_stage.split(',').map((val) => (val === 'None' ? val : Number(val)))
      : [],
    custom_tags: custom_tags ? custom_tags.split(',') : [],
  });
  const selectedDropdownOptions = [
    {
      label: 'Update Open Alerts',
      value: 'alerts',
      iconStart: <CheckCircle size={17} />,
    },
    {
      label: 'Add to Case',
      value: 'case',
      iconStart: <FolderPlus size={17} />,
    },
  ];
  const [entityNameVerboseOptions, setEntityNameVerboseOptions] = React.useState([]);
  const selectedTab = tabData.findIndex((item) => item === tab);
  const [currentTab, setTab] = React.useState<number>(selectedTab);
  const [isAllAddressSelected, setIsAllAddressSelected] = React.useState(false);
  const [selectedAddresses, setSelectedAddresses] = React.useState<number[]>([]);
  const [isAddToCaseModalOpen, setIsAddToCaseModalOpen] = React.useState(false);
  const [closedStage, setClosedStage] = React.useState<IStageListLite>();
  const [disableAllTabs, setDisableAllTabs] = React.useState(true);
  const [tokenModal, setTokenModal] = React.useState<TokenModalType>(null);
  const [openAlertsModal, setAlertsModal] = React.useState(false);

  useAddressGetAddressNameVerboses({
    onSuccess: ({ data }) => {
      setEntityNameVerboseOptions(
        data.results.map((option) => ({
          label: option,
          value: option,
        }))
      );
    },
  });

  const getAssignmentCountOptions = (workspaces: IGetWorkspaceAssignmentCountsResponse) => {
    if (workspaces?.total === 0) {
      return [];
    }
    return workspaces?.workspaces?.map((r) => {
      return {
        label: r.name,
        value: r.slug,
        iconEnd: (
          <div className='ml-2 flex items-center gap-2'>
            <div className='whitespace-nowrap'>{`${r.count} assigned ${
              r.count > 1 ? 'addresses' : 'address'
            }`}</div>
            <div className='rounded border border-gray-200 p-1'>
              <ArrowSquareOut size={22} />
            </div>
          </div>
        ),
      };
    });
  };

  const workspaceAssignmentCount = useWorkspaceAssignmentCountGet({ type: 'addresses' });
  const crossWorkspaceOptions = getAssignmentCountOptions(workspaceAssignmentCount?.data?.data);

  const stagesListLiteQuery = useStagesListLiteGet(false);
  const customTagsListQuery = useCustomTagsGetCustomTags({ is_in_custom_list: false });
  const [, customTagsData] = flattenInfiniteQueryResult(customTagsListQuery.data);
  const customTagsOptions = customTagsData.map((tag) => {
    return {
      label: tag.name,
      value: tag.id.toString(),
    };
  });

  const addressQueryAll = useAddressGetAddressList({ offset: 0, filters });

  const addressQueryAssigned = useAddressGetAddressList({
    offset: 0,
    filters,
    assigned_to: state.userProfile?.id,
  });

  const addressQueryUnassigned = useAddressGetAddressList({ offset: 0, filters, assigned_to: 'None' });

  const addressQueryClosed = useAddressGetAddressList(
    { offset: 0, filters, workflow_stage: closedStage?.id, closed: true },
    { enabled: !!closedStage }
  );

  const isLoading =
    addressQueryAll.isLoading &&
    addressQueryAssigned.isLoading &&
    addressQueryUnassigned.isLoading &&
    addressQueryClosed.isLoading &&
    stagesListLiteQuery.isLoading &&
    customTagsListQuery.isLoading;

  const [allAddressesCount, allAddressesData] = flattenInfiniteQueryResult(addressQueryAll?.data);
  const [assignedAddressesCount, assignedAddressesData] = flattenInfiniteQueryResult(
    addressQueryAssigned?.data
  );
  const [unAssignedAdressesCount, unAssignedAddressesData] = flattenInfiniteQueryResult(
    addressQueryUnassigned?.data
  );
  const [closedAddressesCount, closedAddressesData] = flattenInfiniteQueryResult(addressQueryClosed?.data);

  const tabs = [
    { label: 'Assigned to me', count: assignedAddressesCount, slug: 'assigned_to_me' },
    {
      label: 'Unassigned',
      count: unAssignedAdressesCount,
      slug: 'unassigned',
    },
    {
      label: 'Closed',
      count: stagesListLiteQuery?.data?.data?.count && closedAddressesCount,
      slug: 'closed',
    },
    {
      label: 'All Addresses',
      count: allAddressesCount,
      slug: 'all_addresses',
    },
  ];

  function getAddressData(tab: number) {
    //TODO: need to add a interface for selectedQuery
    let addresses: IAddressResponse[], count: number, selectedQuery;

    switch (tab) {
      case 0:
        addresses = assignedAddressesData;
        count = assignedAddressesCount;
        selectedQuery = addressQueryAssigned;
        break;
      case 1:
        addresses = unAssignedAddressesData;
        count = unAssignedAdressesCount;
        selectedQuery = addressQueryUnassigned;
        break;
      case 2:
        addresses = closedAddressesData;
        count = closedAddressesCount;
        selectedQuery = addressQueryClosed;
        break;
      default:
        addresses = allAddressesData;
        count = allAddressesCount;
        selectedQuery = addressQueryAll;
    }

    return { addresses, count, selectedQuery };
  }

  const { addresses, count, selectedQuery } = getAddressData(currentTab);

  React.useEffect(() => {
    setClosedStage(stagesListLiteQuery?.data?.data.results.find((item) => item.is_final_stage));
  }, [stagesListLiteQuery]);

  React.useEffect(() => {
    if (selectedTab < 0 && assignedAddressesCount !== 0 && !addressQueryAssigned.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultAddressListFilters;
      setFilters(localStorageFilters);
      onChange(0, localStorageFilters);
    } else if (assignedAddressesCount === 0 && currentTab === -1 && !addressQueryAssigned.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultAddressListFilters;
      setFilters(localStorageFilters);
      onChange(3, localStorageFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressQueryAssigned.isLoading]);

  React.useEffect(() => {
    const localStorageFilters = filterStorage
      ? formatLocalStorageValue(filterStorage)
      : defaultAddressListFilters;

    workspace.workspaces.forEach((w) => {
      if (!localStorage.getItem(`compass.storage.addressFilters.${w.slug}`)) {
        localStorage.setItem(
          `compass.storage.addressFilters.${w.slug}`,
          JSON.stringify(defaultAddressListFilters)
        );
      }
    });

    if (isEqual(filters, defaultAddressListFilters)) {
      setFilters(localStorageFilters);
      syncFilters(localStorageFilters, currentTab);
    } else {
      setFilterStorage(filters);
      setDisableAllTabs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      if (filterStorage) {
        setFilters(filterStorage);
        Object.keys(filterStorage).forEach((key) => {
          if (
            filterStorage[key] !== null &&
            (!Array.isArray(filterStorage[key]) || filterStorage[key].length !== 0)
          ) {
            newSearchParams.set(key, filterStorage[key].toString());
          }
        });
      }
      // Add delay to wait for the tab to be set
      setTimeout(() => {
        const { tab } = getQueryParams();
        if (tab) newSearchParams.set('tab', tab);
        navigate(`/addresses`, Object.fromEntries(newSearchParams));
      }, 100);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStorage]);

  const syncFilters = (newFilters: IFilters, tab_id?: number) => {
    if (state?.userProfile.email) {
      if (tab_id !== -1 || tab_id == null) {
        const newSearchParams = new URLSearchParams();
        newSearchParams.set('tab', tabs[tab_id || tab_id === 0 ? tab_id : currentTab ? currentTab : 0]?.slug);
        Object.keys(newFilters).forEach((key) => {
          if (newFilters[key] !== null && (!Array.isArray(newFilters[key]) || newFilters[key].length !== 0)) {
            newSearchParams.set(key, newFilters[key].toString());
          }
        });
        setFilterStorage(newFilters);
        navigate(`/addresses`, Object.fromEntries(newSearchParams));
        setDisableAllTabs(false);
      }
    }
  };

  const formatLocalStorageValue = (unformattedFilter: IFilters): IFilters => {
    const val = {};
    Object.keys(unformattedFilter).forEach((key) => {
      if (unformattedFilter[key] === 'null') {
        val[key] = null;
      } else {
        val[key] = unformattedFilter[key];
      }
    });
    return val as IFilters;
  };

  const handleApply = (newFilters: IFilters) => {
    setFilters(newFilters);
    syncFilters(newFilters);
  };

  const onReset = () => {
    setFilters(defaultAddressListFilters);
    syncFilters(defaultAddressListFilters);
  };

  const selectAddress = (id) => {
    if (selectedAddresses?.includes(id)) {
      setSelectedAddresses(selectedAddresses.filter((i) => i !== id));
    } else {
      setSelectedAddresses([...selectedAddresses, id]);
    }
  };

  const selectAllAddresses = (force = false) => {
    if (force) {
      setSelectedAddresses(addresses.map((address) => address.id));
    } else {
      if (selectedAddresses.length === addresses.length) {
        setSelectedAddresses([]);
        setIsAllAddressSelected(false);
      } else {
        setSelectedAddresses(addresses.map((address) => address.id));
      }
    }
  };

  const downloadSelectedAddresses = async () => {
    if (isAllAddressSelected) {
      if (selectedTab === 3) {
        const res = await addressApi.exportAddressList({
          ids: isAllAddressSelected ? [] : selectedAddresses,
          filters,
        });
        toast.success(res.data.message);
      } else if (selectedTab === 0) {
        const res = await addressApi.exportAddressList({
          ids: isAllAddressSelected ? [] : selectedAddresses,
          filters,
          assigned_to: state.userProfile?.id,
        });
        toast.success(res.data.message);
      } else if (selectedTab === 1) {
        const res = await addressApi.exportAddressList({
          ids: isAllAddressSelected ? [] : selectedAddresses,
          filters,
          assigned_to: 'None',
        });
        toast.success(res.data.message);
      } else if (selectedTab === 2) {
        const res = await addressApi.exportAddressList({
          ids: isAllAddressSelected ? [] : selectedAddresses,
          filters,
          workflow_stage: closedStage?.id,
          closed: true,
        });
        toast.success(res.data.message);
      }
    } else {
      const res = await addressApi.exportAddressList({
        ids: selectedAddresses,
      });
      toast.success(res.data.message);
    }
  };

  const onChange = (tab: number, newFilters?: IFilters) => {
    setTab(tab);
    setSelectedAddresses([]);
    setIsAllAddressSelected(false);
    if (newFilters) {
      syncFilters(newFilters, tab);
    } else {
      syncFilters(filters, tab);
    }
  };

  const onChangeSelectedDropdown = (value: DropdownOption) => {
    if (value.value === 'alerts') {
      setAlertsModal(true);
    } else if (value.value === 'case') {
      setIsAddToCaseModalOpen(true);
    }
  };

  const onChangeWorkspacesDropdown = (value: DropdownOption) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.search = '';
    currentUrl.searchParams.set('workspace', value.value.toString());
    window.open(currentUrl.toString(), '_blank');
  };

  const addressData = useGetAddressData({
    selectAllAddresses,
    addresses,
    selectedAddresses,
    selectAddress,
    setTokenModal,
  });

  const headerData: (string | JSX.Element)[] = addressData.headerData;
  const rows: ITableRow[] = addressData.rowData;

  return (
    <>
      <Table
        title='Addresses'
        headerData={headerData}
        rows={rows}
        count={`Showing ${rows?.length} of ${count ?? 0} results`}
        tab={tab}
        headerActionsLeft={
          <Tab
            values={tabs}
            defaultIndex={currentTab ?? null}
            type='primary'
            listClassName='rounded-t-md'
            inactiveClassName='text-gray-800/30 border-gray-200 cursor-not-allowed'
            panelClassName='px-4'
            isLoading={isLoading}
            onChange={onChange}
            changeTab={currentTab ?? null}
            disableAllTabs={disableAllTabs}
          />
        }
        filterComponent={
          <>
            {!workspaceAssignmentCount.isError &&
              workspaceAssignmentCount?.data?.data.total !== 0 &&
              !workspaceAssignmentCount.isLoading && (
                <SimpleDropdown
                  value={null}
                  onChange={onChangeWorkspacesDropdown}
                  placeholder={
                    <Button variant='tertiary' className='m-1 px-3'>
                      <div className='pr-2 text-2xs font-medium text-gray-800'>
                        {`${
                          workspaceAssignmentCount?.data?.data.total
                            ? workspaceAssignmentCount?.data?.data.total === 1
                              ? `${workspaceAssignmentCount?.data?.data.total} Cross-Workspace Address`
                              : `${workspaceAssignmentCount?.data?.data.total} Cross-Workspace Addresses`
                            : '0 Cross-Workspace Address'
                        }`}
                      </div>
                      <CaretDown weight='bold' fill='fill' size={16} className={classNames('mr-0.5')} />
                    </Button>
                  }
                  options={crossWorkspaceOptions}
                />
              )}
            {selectedAddresses.length > 0 && (
              <SimpleDropdown
                value={null}
                onChange={onChangeSelectedDropdown}
                placeholder={
                  <Button variant='tertiary' className='my-1 px-3'>
                    <DotsThreeOutlineVertical size={16} weight='fill' />
                  </Button>
                }
                options={selectedDropdownOptions}
              />
            )}
            <AddressListFilter
              filters={filters}
              onApply={handleApply}
              onReset={onReset}
              disabled={selectedAddresses.length > 0}
              entityNameVerboseOptions={entityNameVerboseOptions}
              tab={currentTab}
              customTagsOptions={customTagsOptions}
            />
          </>
        }
        appliedFilters={
          <AddressListAppliedFilter
            count={count}
            filters={filters}
            setFilters={handleApply}
            selectedAddresses={selectedAddresses}
            setSelectedAddresses={setSelectedAddresses}
            isAllAddressSelected={isAllAddressSelected}
            setIsAllAddressSelected={setIsAllAddressSelected}
            selectAllAddresses={selectAllAddresses}
            downloadSelectedAddresses={downloadSelectedAddresses}
            stageOptions={stagesListLiteQuery?.data?.data?.results}
            customTagsOptions={customTagsOptions}
            tab={currentTab}
            selectedText={
              isAllAddressSelected
                ? `${count} addresses selected.`
                : selectedAddresses.length === addresses.length
                  ? `All ${selectedAddresses.length} addresses in this page are selected.`
                  : `${selectedAddresses.length} ${
                      selectedAddresses.length > 1 ? 'addresses' : 'address'
                    } selected.`
            }
          />
        }
        heightOffset={25.5}
        isSelectable
        {...buildInfiniteQueryTableProps(selectedQuery)}
      />
      <TokenAccountModal tokenModal={tokenModal} setTokenModal={setTokenModal} />
      <ResolveMultipleAlertsModal
        type='address'
        isOpen={openAlertsModal}
        isAllEntitiesSelected={isAllAddressSelected}
        selectedEntities={selectedAddresses}
        refetchList={selectedQuery.refetch}
        onClose={() => {
          setAlertsModal(false);
        }}
        selectedTab={currentTab}
        filters={filters}
        closedStage={closedStage}
        setSelectedEntities={setSelectedAddresses}
        setIsAllEntitiesSelected={setIsAllAddressSelected}
      />
      <AddToCaseModal
        type='address'
        isOpen={isAddToCaseModalOpen}
        onClose={() => setIsAddToCaseModalOpen(false)}
        entities={selectedAddresses.map((address) => {
          let data;
          addresses.forEach((add) => {
            if (add.id === address) {
              data = {
                identifier: add.identifier,
                currency: add.currency,
              };
            }
          });
          return data;
        })}
        isAllEntitiesSelected={isAllAddressSelected}
        selectedTab={selectedTab}
        filters={filters}
        closedStage={closedStage}
      />
    </>
  );
};

export default AddressList;
