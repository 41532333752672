import { FC } from 'react';

interface HighlightedIdentifierProps {
  identifier: string;
  highlightedText: string;
}

const HighlightedIdentifier: FC<HighlightedIdentifierProps> = ({ identifier, highlightedText }) => {
  const parts = identifier.split(new RegExp(`(${highlightedText})`, 'gi'));

  return (
    <code className='text-2xs text-blue-500'>
      {parts.map((part: string, index: number) =>
        part.toLowerCase() === highlightedText.toLowerCase() ? (
          <span
            key={index}
            className='bg-yellow-200'
            style={{
              display: 'inline',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}>
            {part}
          </span>
        ) : (
          <span key={index} style={{ wordBreak: 'break-word' }}>
            {part}
          </span>
        )
      )}
    </code>
  );
};

export default HighlightedIdentifier;
