import { FC, useState } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { IAddressResponse } from '../../api/dtos/address';
import Table, { ITableRow } from '../../ui/components/Table/Table';
import Sidebar from '../../components/ui/components/Sidebar/Sidebar';
import { useGetAddressData } from '../dashboard/addresses/AddressData';
import TokenAccountModal, { TokenModalType } from '../../components/Address/AddressList/TokenAccountModal';
import { capitalize } from 'lodash';
import { useGetTransactionData } from '../dashboard/transactions/TransactionData';
import { ITransactionResponse } from '../../api/dtos/transaction';

interface AddressListPreviewProps {
  open: boolean;
  count: number;
  hasNextPage: boolean;
  type: 'address' | 'transaction';
  entitiesQuery: UseInfiniteQueryResult;
  addresses?: IAddressResponse[];
  transactions?: ITransactionResponse[];
  setOpen: () => void;
}

const ListPreview: FC<AddressListPreviewProps> = ({
  type,
  open,
  count,
  addresses,
  hasNextPage,
  transactions,
  entitiesQuery,
  setOpen,
}) => {
  const [tokenModal, setTokenModal] = useState<TokenModalType>(null);
  const addressData = useGetAddressData({
    addresses,
    setTokenModal,
    type: 'search',
  });
  const transactionData = useGetTransactionData({
    transactions,
    type: 'search',
  });
  const entitiesData = type === 'address' ? addressData : transactionData;

  const headerData: (string | JSX.Element)[] = entitiesData.headerData;
  const rows: ITableRow[] = entitiesData.rowData;
  return (
    <>
      <Sidebar
        title={`${capitalize(type)} List Preview`}
        isOpen={open}
        onClose={() => setOpen()}
        titleClass='!py-2'
        childrenClass='!my-2 !py-0'
        maxWidthClass='max-w-5xl'>
        <Table
          tab={type}
          title={`${capitalize(type)}`}
          headerData={headerData}
          rows={rows}
          count={`Showing ${rows?.length} of ${count ?? 0} results`}
          heightOffset={35}
          isSearchPage
          isSelectable
          isLoading={entitiesQuery.isLoading}
          hasNextPage={hasNextPage}
          isFetchingNextPage={entitiesQuery.isFetchingNextPage}
          fetchNextPage={entitiesQuery.fetchNextPage}
        />
      </Sidebar>
      <TokenAccountModal tokenModal={tokenModal} setTokenModal={setTokenModal} />
    </>
  );
};

export default ListPreview;
