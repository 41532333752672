import { FC } from 'react';
import CurrencyBadge from '../../../../components/ui/components/Badge/CurrencyBadge';
import HighlightedIdentifier from '../../HighlightedIdentifier/HighlightedIdentifier';
import { ScreeningStatusBadge, TypeBadge } from '..';
import { IAddressResponse } from '../../../../api/dtos/address';
import { ITransactionResponse } from '../../../../api/dtos/transaction';
import { ICustomerResponse } from '../../../../api/dtos/customer';
import { ICaseResponse } from '../../../../api/dtos/case';
import { useRouter } from '../../../../modules/router/RouterProvider';
import classNames from 'classnames';
import { ICurrentEntityType } from '../../../../views/search/SearchPage';
import { getTypeApi, useScreenApi } from '../../../../utils/helpers/apiHelpers';

interface StageBadgeProps {
  q: string;
  type: 'address' | 'transaction' | 'customer' | 'case';
  entity: IAddressResponse &
    ITransactionResponse &
    ICustomerResponse &
    ICaseResponse & { item_type: string; is_screened?: boolean; sanctioned?: boolean };
  setCurrentEntity: (val: ICurrentEntityType) => void;
}

const SearchBadge: FC<StageBadgeProps> = (props) => {
  const { q, type, entity, setCurrentEntity } = props;
  const screenApi = useScreenApi();
  const { navigate } = useRouter();
  const identifier = ['address', 'transaction'].includes(type) ? entity.identifier : null;
  const onBadgeClick = async () => {
    if (entity?.sanctioned) {
      screenApi({
        identifier,
        currency: entity.currency,
        entityType: getTypeApi(type) as 'addresses' | 'transactions',
      });
      return;
    }

    if (type === 'customer') {
      navigate(`/customers/${entity.customer_id}`);
      return;
    }

    if (type === 'case') {
      navigate(`/cases/${entity.id}`);
      return;
    }

    setCurrentEntity({
      id: entity.identifier,
      currency: entity.currency,
      type,
      open_type: entity?.is_screened === false ? 'preview' : 'list_preview',
    });
  };

  return (
    <div
      className={classNames(
        'flex h-[90px] min-w-[24%] max-w-[24%] cursor-pointer flex-col gap-2 rounded-lg border p-2',
        {
          'border-indigo-500': type === 'address',
          'border-pink-500': type === 'transaction',
          'border-yellow-500': type === 'customer',
          'border-purple-500': type === 'case',
        }
      )}
      onClick={onBadgeClick}>
      <div className='flex flex-wrap text-2xs'>
        {['customer', 'case'].includes(entity?.item_type) && (entity?.customer_id || entity?.case_id) ? (
          <p>
            <HighlightedIdentifier identifier={entity.customer_id || entity.case_id} highlightedText={q} />
          </p>
        ) : (
          identifier && <HighlightedIdentifier identifier={identifier} highlightedText={q} />
        )}
      </div>
      <div className='flex flex-wrap gap-2 text-2xs'>
        <CurrencyBadge currency={entity.currency} className='w-fit !text-2xs' iconWidth={12} />
        <TypeBadge type={type} />
        {['address', 'transaction'].includes(entity.item_type) && entity?.is_screened !== false && (
          <ScreeningStatusBadge isActive className='!text-2xs' />
        )}
      </div>
    </div>
  );
};

export default SearchBadge;
