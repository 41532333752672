import { ReactNode, FC } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { ArrowsInSimple, ArrowsOutSimple } from '@phosphor-icons/react';
import classNames from 'classnames';
import React from 'react';

interface CustomDisclosureProps {
  title: string;
  defaultOpen?: boolean;
  children: ReactNode;
  className?: string;
}

const CustomDisclosure: FC<CustomDisclosureProps> = ({ title, defaultOpen = false, children, className }) => {
  return (
    <Disclosure as='div' className='p-6' defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className={classNames('rounded-lg border border-blue-600 bg-gray-50', className)}>
          <DisclosureButton className='group relative flex w-full items-center justify-between rounded-lg px-3 py-2 shadow-md'>
            <div className='text-2xs font-medium text-gray-500'>{title}</div>
            <div className='flex gap-1 rounded-lg bg-blue-100 p-1.5 text-xs'>
              <div>{open ? 'Collapse' : 'Expand'}</div>
              {open ? <ArrowsInSimple size={15} /> : <ArrowsOutSimple size={15} />}
            </div>
          </DisclosureButton>
          <Transition
            as='div'
            enter='duration-200 ease-out'
            enterFrom='opacity-0 -translate-y-6'
            enterTo='opacity-100 translate-y-0'
            leave='duration-300 ease-out'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 -translate-y-6'>
            <DisclosurePanel className='align-stretch flex max-h-[400px] origin-top flex-wrap gap-4 overflow-scroll rounded-b-lg p-4 transition'>
              {children}
            </DisclosurePanel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};

export default CustomDisclosure;
